import React, { useState, useRef, forwardRef } from 'react';
import { ContentItem, SocialMediaLink } from '../types';
import { updateContent, uploadImage, db } from '../services/firebase';
import { useAuth } from '../contexts/AuthContext';
import { ThumbsUp, ThumbsDown, Clock, DollarSign, Share2, Copy,  MapPin, Flame, Dribbble, ArrowUpRight, Instagram, Twitter, Youtube } from 'lucide-react';
import CustomSelect from './ui/CustomSelect';
import { User } from 'firebase/auth';
import SocialMediaPreview from './SocialMediaPreview';
import SocialMediaInput from './SocialMediaInput';
import { generateSlug } from './utils/generateSlug';
import { useNavigate } from 'react-router-dom';
import { deleteDoc, doc } from 'firebase/firestore';
import { RiShareForwardLine } from "react-icons/ri";


interface HeaderSectionProps {
  content: ContentItem;
  onVote: (voteType: 'up' | 'down') => void;
  upvotes: number;
  downvotes: number;
  userVote: 'up' | 'down' | null;
  user: User | null;
  userRole: string | null;
}


const flRatingOptions = [
  {
    value: "Pee Wee Level 1 | Basic",
    label: ["Pee Wee", "Level 1 | Basic"]
  },
  {
    value: "Two Hand Touch Level 2 | Challenging",
    label: ["Two Hand Touch", "Level 2 | Challenging"]
  },
  {
    value: "Full Contact Level 3 | Serious",
    label: ["Full Contact", "Level 3 | Serious"]
  },
  {
    value: "All Pro Elite Level 4 | Ruthless",
    label: ["All Pro Elite", "Level 4 | Ruthless"]
  },
  {
    value: "No Mercy Level 5 | Insane",
    label: ["No Mercy", "Level 5 | Insane"]
  }
];

interface PunishmentDisplayProps {
  content: ContentItem;
  userRole: string | null;
  onVote: (voteType: 'up' | 'down') => void;
  upvotes: number;
  downvotes: number;
  userVote: 'up' | 'down' | null;
}

const QuickLinks: React.FC<{
  onInstructionsClick: () => void;
  onCommunityClick: () => void;
}> = ({ onInstructionsClick, onCommunityClick }) => {
  return (
    <div className="w-full">
      <div className="max-w-7xl mx-auto px-4 border-b-2 ">
        <div className="flex flex-col sm:flex-row justify-left gap-4 ">
          <button
            onClick={onInstructionsClick}
            className="group flex items-center justify-center gap-3 bg-white hover:border-b-2 hover:border-black  px-6 py-3 rounded-lg transition-colors duration-300"
          >
            <span className="font-antonio text-3xl">Instructions</span>
          </button>
          <button
            onClick={onCommunityClick}
            className="group flex items-center justify-center gap-3 bg-white hover:border-b-2 hover:border-black 2 px-6 py-3 rounded-lg transition-colors duration-300"
          >
            <span className="font-antonio text-3xl">Community Inspiration</span>
          </button>
        </div>
      </div>
    </div>
  );
};






const HeaderSection: React.FC<HeaderSectionProps> = ({ 
  content, 
  onVote,
  upvotes,
  downvotes,
  userVote,
  user,
  userRole
}) => {

  const [votingType, setVotingType] = useState<'up' | 'down' | null>(null);

  const handleShare = async () => {
    const url = window.location.href;
    try {
      await navigator.clipboard.writeText(url);
      // You could add a toast notification here
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy link:', err);
      alert('Failed to copy link. Please try again.');
    }
  };


  const handleVote = async (voteType: 'up' | 'down') => {
    if (!user || (userRole !== 'commissioner' && userRole !== 'admin')) {
      return;
    }

    try {
      setVotingType(voteType);
      await onVote(voteType);
    } catch (error) {
      console.error('Error voting:', error);
    } finally {
      setVotingType(null);
    }
  };

  const getVoteButtonTooltip = (voteType: 'up' | 'down') => {
    if (!user) return "Please sign in to vote";
    if (userRole !== 'commissioner' && userRole !== 'admin') {
      return "Only commissioners and admins can vote";
    }
    if (votingType === voteType) return "Processing vote...";
    return "";
  };

  // Parse the rating details
  const getRatingDetails = () => {
    if (!content.flRating) return { level: '', description: '' };
    const match = content.flRating.match(/(.+) Level (\d+)/);
    return {
      level: match ? match[2] : '',
      description: match ? match[1].trim() : ''
    };
  };

  const { level, description } = getRatingDetails();

  const ratingIcons = (count: number, filled: boolean = true) => (
    <div className="flex">
      {[...Array(count)].map((_, i) => (
        <img
          key={i}
          src={require("../assets/FL_Logo_Loser_Icon_Grn.png")}
          alt={`Rating icon ${i + 1}`}
          className={`w-6 h-6 ${filled ? 'opacity-100' : 'opacity-30'}`}
        />
      ))}
      {[...Array(5 - count)].map((_, i) => (
        <img
          key={`empty-${i}`}
          src={require("../assets/FL_Logo_Loser_Icon_Grn.png")}
          alt={`Empty rating icon ${i + 1}`}
          className="w-6 h-6 opacity-30"
        />
      ))}
    </div>
  );

  return (
    <div className=" bg-[#1a1a1a] py-4 ">
      <div className="max-w-[1500px] mx-auto">
        <div className="relative w-full h-[833px] overflow-hidden">
          {/* Background Image with Overlay */}
          <div className="absolute inset-0">
          {content.image && (
          <img 
            src={typeof content.image === 'string' ? content.image : URL.createObjectURL(content.image)}
            alt={content.title}
            className={`w-full h-full object-cover rounded-xl
              md:block
              ${content.mobileHeaderImage ? 'hidden' : 'block'}
            `}
          />
        )}
        {content.mobileHeaderImage && (
          <img 
            src={typeof content.mobileHeaderImage === 'string' ? content.mobileHeaderImage : URL.createObjectURL(content.mobileHeaderImage)}
            alt={`${content.title} mobile header`}
            className="w-full h-full object-cover rounded-xl block md:hidden"
          />
        )}
            <div className="absolute inset-0 bg-gradient-to-t from-[#1a1a1a] to-transparent" />
          </div>
          {content.imageCredit && (
  <div className="absolute bottom-0 right-0 p-2 bg-black/50 text-white text-sm">
    {content.imageCredit}
  </div>
)}

          {/* Voting Box */}
     <div className="absolute top-6 right-6 bg-[#1a1a1a]/90 backdrop-blur-sm rounded-xl p-4 flex items-center space-x-6 z-10">
     <button
    onClick={handleShare}
    className=" rounded-full transition-colors pointer-events-auto flex items-center justify-center "
    title="Copy link to clipboard"
  >
    <p className="font-antonio text-white text-2xl mx-2">
    Share
    </p>
    
    <RiShareForwardLine className="w-9 h-9 text-[#0fd494]" />
  </button>
  {/* <div className="flex items-center space-x-2">

    <button
      onClick={() => handleVote('up')}
      className={`p-2 rounded-full transition-colors pointer-events-auto flex items-center justify-center ${
        votingType === 'up' ? 'opacity-50 cursor-not-allowed' :
        userVote === 'up' ? 'bg-[#18f7b1]/20' : 'hover:bg-white/10'
      }`}
      disabled={votingType === 'up' || !user || (userRole !== 'commissioner' && userRole !== 'admin')}
      title={getVoteButtonTooltip('up')}
    >
  
      <span className="pointer-events-none">
        <ThumbsUp className={`w-5 h-5 ${userVote === 'up' ? 'text-[#18f7b1]' : 'text-white'}`} />
      </span>
    </button>
    <span className="text-white font-antonio text-lg min-w-[2ch] text-center">{upvotes}</span>
  </div>
  <div className="flex items-center space-x-2">
    <button
      onClick={() => handleVote('down')}
      className={`p-2 rounded-full transition-colors pointer-events-auto flex items-center justify-center ${
        votingType === 'down' ? 'opacity-50 cursor-not-allowed' :
        userVote === 'down' ? 'bg-red-500/20' : 'hover:bg-white/10'
      }`}
      disabled={votingType === 'down' || !user || (userRole !== 'commissioner' && userRole !== 'admin')}
      title={getVoteButtonTooltip('down')}
    >
      <span className="pointer-events-none">
        <ThumbsDown className={`w-5 h-5 ${userVote === 'down' ? 'text-red-500' : 'text-white'}`} />
      </span>
    </button>
    <span className="text-white font-antonio text-lg min-w-[2ch] text-center">{downvotes}</span>
  </div> */}
</div>

          {/* Content Overlay */}
          <div className="absolute inset-0 p-6 flex flex-col justify-end">
            {/* Title */}
            <h1 className="text-6xl sm:text-6xl md:text-6xl lg:text-8xl max-w-4xl font-antonio text-white mb-4 tracking-wide break-words hyphens-auto">
            {content.title}
          </h1>

            {/* Subtitle */}
            <p className="text-xl text-gray-200 mb-6 max-w-3xl font-antonio">
              {content.subtitle}
            </p>

            {/* Stats Row */}
              <div className="grid grid-cols-2 md:flex md:space-x-8 gap-4 md:gap-0">
                <div>
                  <span className="block text-white mb-1 font-antonio">Shame:</span>
                  {ratingIcons(parseInt(content.embarrassmentLevel) || 0)}
                </div>
                <div>
                  <span className="block text-white mb-1 font-antonio">Danger:</span>
                  {ratingIcons(parseInt(content.riskLevel) || 0)}
                </div>
                <div>
                  <span className="block text-white mb-1 font-antonio">Effort:</span>
                  {ratingIcons(parseInt(content.longTermEffects) || 0)}
                </div>
                <div>
                  <div className="flex items-baseline space-x-2">
                    <span className="block text-white mb-1 font-antonio">Level</span>
                    <span className="text-white font-antonio">{level}</span>
                  </div>
                  <span className="text-[#18f7b1] font-antonio text-lg block">
                    {description || 'Not Rated'}
                  </span>
                </div>
              </div>
        </div>
      </div>
    </div>
    </div>
  );
};

interface PunishmentInfoSectionProps {
  timeToComplete: string;
  costToComplete: string;
  punishmentLocation: string;
  imageDescription?: string;
}

const InfoCard: React.FC<{
  icon: React.ReactNode;
  title: string;
  value: string;
}> = ({ icon, title, value }) => (
  <div className="flex-1 bg-white rounded-lg p-6 shadow-sm ">
    <div className="flex items-center gap-3 mb-3">
      <div className=" rounded-full">
        {icon}
      </div>
      <h3 className="font-bold font-antonio text-2xl">
        {title}:
      </h3>
    </div>
    <p className="text-lg font-semibold text-gray-900">
      {value}
    </p>
  </div>
);

const PunishmentInfoSection: React.FC<PunishmentInfoSectionProps> = ({
  timeToComplete,
  costToComplete,
  punishmentLocation,
  imageDescription
}) => {

  const instructionsRef = useRef<HTMLDivElement>(null);
  const communityRef = useRef<HTMLDivElement>(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  return (
    <div className="w-full max-w-7xl mx-auto -mt-10 px-4 relative z-10">
      {/* Description Section */}
<div className="w-full max-w-7xl mx-auto -mt-10 px-4 relative z-10">
  <div className="rounded-lg p-6 text-center mt-12">
    {imageDescription ? (
      <div className="flex flex-col items-center">
        <p className="text-xl text-gray-900 font-roboto leading-relaxed">
          {imageDescription.length > 350 ? (
            <>
              {showFullDescription ? 
                imageDescription : 
                `${imageDescription.substring(0, 350)}...`
              }
              <button
                onClick={() => setShowFullDescription(!showFullDescription)}
                className="ml-2 text-[blue] hover:text-[#0fd494] font-medium transition-colors duration-200"
              >
                {showFullDescription ? 'Read Less' : 'Read More'}
              </button>
            </>
          ) : (
            imageDescription
          )}
        </p>
      </div>
    ) : (
      <p className="text-xl text-gray-900 font-roboto leading-relaxed">
        No description available
      </p>
    )}
  </div>
</div>
   
      <div className="flex flex-col md:flex-row gap-4 border-b border-t border-gray-300">
        <InfoCard
          icon={<Clock className="w-5 h-5 " />}
          title="Time to Complete"
          value={timeToComplete || 'Not specified'}
        />
        <InfoCard
          icon={<DollarSign className="w-5 h-5 " />}
          title="Cost to Complete"
          value={costToComplete || 'Not specified'}
        />
        <InfoCard
          icon={<MapPin className="w-5 h-5 " />}
          title="Punishment Location"
          value={punishmentLocation || 'Not specified'}
        />
      </div>
    </div>

  );
};


const PunishmentDisplay: React.FC<PunishmentDisplayProps> = ({
  content,
  userRole,
  onVote,
  upvotes,
  downvotes,
  userVote
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);
  const [newImage, setNewImage] = useState<File | null>(null);
  const { user } = useAuth();
  const [newCardImage, setNewCardImage] = useState<File | null>(null);
  const [newMobileHeaderImage, setNewMobileHeaderImage] = useState<File | null>(null);


const navigate = useNavigate();

const handleDeletePunishment = async () => {
  if (!window.confirm('Are you sure you want to delete this punishment? This action cannot be undone.')) {
    return;
  }

  try {
    await deleteDoc(doc(db, 'content', content.id));
    navigate('/explore');
  } catch (error) {
    console.error('Error deleting punishment:', error);
    alert('Failed to delete punishment. Please try again.');
  }
};

  const handleAddSocialLink = (link: Omit<SocialMediaLink, 'id'>) => {
    const newLink: SocialMediaLink = {
      ...link,
      id: Math.random().toString(36).substr(2, 9) // Simple unique ID generation
    };
    
    setEditedContent(prev => ({
      ...prev,
      socialMediaLinks: [...(prev.socialMediaLinks || []), newLink]
    }));
  };
  
  const handleDeleteSocialLink = (id: string) => {
    setEditedContent(prev => ({
      ...prev,
      socialMediaLinks: (prev.socialMediaLinks || []).filter(link => link.id !== id)
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditedContent(prev => ({ 
      ...prev, 
      [name]: name === 'type' ? value as 'punishment' | 'challenge' | 'draft' : value 
    }));
  };

  const handleRemoveArrayItem = (index: number, field: keyof ContentItem) => {
    const newArray = [...(editedContent[field] as string[])];
    newArray.splice(index, 1);
    setEditedContent(prev => ({ ...prev, [field]: newArray }));
  };

  const handleArrayInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, field: keyof ContentItem) => {
    const newArray = [...(editedContent[field] as string[])];
    newArray[index] = e.target.value;
    setEditedContent(prev => ({ ...prev, [field]: newArray }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setNewImage(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      let updatedContent = { ...editedContent };

      // Handle main image upload if there's a new image
      if (newImage) {
        const imageUrl = await uploadImage(newImage);
        updatedContent.image = imageUrl;
      }

      if (newCardImage) {
        const cardImageUrl = await uploadImage(newCardImage);
        updatedContent.cardImage = cardImageUrl;
      }

      if (newMobileHeaderImage) {
        const mobileHeaderImageUrl = await uploadImage(newMobileHeaderImage);
        updatedContent.mobileHeaderImage = mobileHeaderImageUrl;
      }
  

      // Handle instruction image uploads
      const updatedInstructions = await Promise.all(
        updatedContent.instructions.map(async (instruction) => {
          if (instruction.image instanceof File) {
            const imageUrl = await uploadImage(instruction.image);
            return { ...instruction, image: imageUrl };
          }
          return instruction;
        })
      );

      updatedContent.instructions = updatedInstructions;

      // Update the content in Firebase
      await updateContent(editedContent.id, updatedContent);
      
      // Exit editing mode
      setIsEditing(false);

      // Show a success message
      alert('Content updated successfully!');

      // Force a page refresh
      window.location.reload();
    } catch (error) {
      console.error('Error updating punishment:', error);
      // Show an error message
      alert('An error occurred while updating the content. Please try again.');
    }
  };

  interface InstructionStep {
    description: string;
    image: string | File | null;
  }
  
  interface InstructionsSectionProps {
    equipmentNeeded: string[];
    instructions: InstructionStep[];
  }
  
  const InstructionsSection = forwardRef<HTMLDivElement, InstructionsSectionProps>(({
    equipmentNeeded,
    instructions,
  }, ref) => {
    return (
      <div ref={ref} className="w-full max-w-5xl mx-auto px-4">
        <h2 className="text-6xl font-bold mb-8 font-antonio">INSTRUCTIONS</h2>

        {/* Equipment Needed with Image Placeholder */}
        <div className="mb-8 bg-white p-6 shadow-sm border-b border-gray-300">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Equipment List */}
          <div className="w-full md:w-1/2">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 rounded-full">
                <Dribbble className="w-7 h-7" />
              </div>
              <h3 className="text-2xl font-bold text-[#1a1a1a] font-antonio">
                EQUIPMENT NEEDED
              </h3>
            </div>
            <ul className="space-y-4">
              {equipmentNeeded.map((item, index) => (
                <li key={index} className="flex items-center gap-3 justify-beginning">
                  <div className="w-2 h-2 rounded-full bg-[#1a1a1a] flex-shrink-0" />
                  <span className="text-[#1a1a1a] font-antonio text-xl text-left">{item}</span>
                </li>
              ))}
            </ul>
          </div>
          
          {/* Image Placeholder */}
          <div className="w-full md:w-1/2 bg-white rounded-lg flex items-center justify-center min-h-[300px]">
            {/* <span className="text-gray-400 font-antonio">Equipment Image</span> */}
          </div>
        </div>
      </div>

        {/* Challenge Steps */}
        <div className="space-y-20">
          {instructions.map((instruction, index) => (
            <div 
              key={index} 
              className="relative bg-white p-8 shadow-sm border-b border-gray-300"
            >
              <div className="absolute -top-4 left-4 bg-[#18f7b1] font-antonio text-[#1a1a1a] px-4 py-[.7px] rounded-md font-bold text-[24px]">
                Step {index + 1}
              </div>

              <div className="mt-4">
                <p className="text-[#1a1a1a] font-roboto text-xl leading-relaxed">
                  {instruction.description}
                </p>

                {instruction.image && (
                  <div className="mt-6">
                    {typeof instruction.image === 'string' ? (
                      <img 
                        src={instruction.image} 
                        alt={`Step ${index + 1}`}
                        className="rounded-lg w-full max-w-xl mx-auto"
                      />
                    ) : (
                      <img 
                        src={URL.createObjectURL(instruction.image)} 
                        alt={`Step ${index + 1}`}
                        className="rounded-lg w-full max-w-xl mx-auto"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  });

  

  const renderImage = (image: string | File | null, alt: string) => {
    if (typeof image === 'string' && image) {
      return (
        <img src={image} alt={alt} className="w-full h-full object-contain" />
      );
    } else if (image instanceof File) {
      return (
        <img src={URL.createObjectURL(image)} alt={alt} className="w-full h-full object-contain" />
      );
    } else {
      return <span className="text-gray-600">No image available</span>;
    }
  };

  const instructionsRef = useRef<HTMLDivElement>(null);
  const communityRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const renderContent = () => (
    <div className="space-y-4 sm:space-y-8 ">
         <HeaderSection 
      content={content}
      onVote={onVote}
      upvotes={upvotes}
      downvotes={downvotes}
      userVote={userVote}
      user={user}
      userRole={userRole}
    />

    

<QuickLinks 
        onInstructionsClick={() => scrollToSection(instructionsRef)}
        onCommunityClick={() => scrollToSection(communityRef)}
      />

       <PunishmentInfoSection
      timeToComplete={content.timeToComplete}
      costToComplete={content.costToComplete}
      punishmentLocation={content.equipmentLocker}
      imageDescription={content.imageDescription}
    />

<InstructionsSection
      ref={instructionsRef}
      equipmentNeeded={content.equipmentNeeded}
      instructions={content.instructions}
    />

        {/* Ways to Spice It Up section */}
        <div className="w-full max-w-5xl mx-auto px-4 mt-20">
        <div className="bg-white p-6 border-b border-gray-300">
          <div className="flex items-center gap-3 mb-4">
            <div className="flex items-center gap-2 bg-[#FF0000] font-antonio text-[#1a1a1a] px-3 py-1 rounded-md font-bold text-xl">
              <Flame className="w-5 h-5 text-[#1a1a1a]" />
              <span>WAYS TO SPICE IT UP</span>
            </div>
          </div>
          <ul className="space-y-3">
            {content.waysToSpiceUp.map((way, index) => (
              <li key={index} className="flex items-center gap-2">
                <div className="w-1.5 h-1.5 rounded-full bg-[#1a1a1a]" />
                <span className="text-[#1a1a1a] font-roboto text-xl">{way}</span>
              </li>
            ))}
          </ul>
        </div>



      </div>

    {userRole === 'admin' && (
  <div className="w-full max-w-7xl mx-auto px-4 flex gap-4">
    <button 
      onClick={() => setIsEditing(true)}
      className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-200"
    >
      Edit
    </button>
    <button 
      onClick={handleDeletePunishment}
      className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-200"
    >
      Delete
    </button>
  </div>
)}

                     {/* Community Inspiration Section */}
                     {content.socialMediaLinks && content.socialMediaLinks.length > 0 && (
    <div ref={communityRef} className="min-w-screen mx-auto px-4 mt-20 bg-[#1a1a1a] text-white text-center">
    <div className=" p-6 ">
      <h2 className="text-4xl sm:text-5xl lg:text-7xl font-bold leading-none font-antonio text-white mb-6 lg:mb-8 uppercase mt-6">COMMUNITY INSPIRATION</h2>
      <p className='text-xl sm:text-2xl lg:text-4xl mb-8 lg:mb-10 font-antonio text-white '>Find Inspiration From Challenges Submitted by Our Community</p>
      <SocialMediaPreview 
        links={content.socialMediaLinks}
        isEditing={isEditing}
        onDelete={handleDeleteSocialLink}
      />
    </div>
  </div>
)}
  </div>)

  const renderEditForm = () => (
    <div className="space-y-6 bg-white p-6 rounded-lg shadow-lg">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Content Type</label>
        <select
          name="type"
          value={editedContent.type}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        >
          <option value="punishment">Punishment</option>
          <option value="challenge">Challenge</option>
          <option value="draft">Draft Content</option>
        </select>
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
        <input
          type="text"
          name="title"
          value={editedContent.title}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>

      <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">URL Slug</label>
      <div className="flex gap-2">
        <input
          type="text"
          name="slug"
          value={editedContent.slug || ''}
          onChange={(e) => {
            const newSlug = generateSlug(e.target.value);
            setEditedContent(prev => ({ ...prev, slug: newSlug }));
          }}
          className="flex-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
          placeholder="custom-url-slug"
        />
        <button
          type="button"
          onClick={() => {
            const newSlug = generateSlug(editedContent.title);
            setEditedContent(prev => ({ ...prev, slug: newSlug }));
          }}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition duration-200"
        >
          Generate from Title
        </button>
      </div>
      <p className="mt-1 text-sm text-gray-500">
        This will be used in the URL: /punishments/<span className="font-mono">{editedContent.slug || 'slug-example'}</span>
      </p>
    </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Subtitle</label>
        <input
          type="text"
          name="subtitle"
          value={editedContent.subtitle}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Main Image</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="mt-1 block w-full bg-gray-50"
        />
      </div>

      <div>
  <label className="block text-sm font-medium text-gray-700 mb-1">Card Image (Optional)</label>
  <input
    type="file"
    accept="image/*"
    onChange={(e) => {
      if (e.target.files && e.target.files[0]) {
        setNewCardImage(e.target.files[0]);
      }
    }}
    className="mt-1 block w-full bg-gray-50"
  />
  <p className="mt-1 text-sm text-gray-500">This image will be used for the card on the explore page. If not provided, the main image will be used.</p>
</div>

<div>
  <label className="block text-sm font-medium text-gray-700 mb-1">Mobile Header Image (Optional)</label>
  <input
    type="file"
    accept="image/*"
    onChange={(e) => {
      if (e.target.files && e.target.files[0]) {
        setNewMobileHeaderImage(e.target.files[0]);
      }
    }}
    className="mt-1 block w-full bg-gray-50"
  />
  <p className="mt-1 text-sm text-gray-500">This image will be used as the header on mobile devices. If not provided, the main image will be used.</p>
</div>

<div>
  <label className="block text-sm font-medium text-gray-700 mb-1">Image Credit</label>
  <input
    type="text"
    name="imageCredit"
    value={editedContent.imageCredit || ''}
    onChange={handleInputChange}
    placeholder="e.g., Photo by John Doe"
    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
  />
</div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Image Description</label>
        <textarea
          name="imageDescription"
          value={editedContent.imageDescription}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
          rows={4}
        />
      </div>

      
  
      <div>
  <label className="block text-sm font-medium text-gray-700 mb-1">Rating</label>
  <CustomSelect
    name="flRating"
    value={editedContent.flRating}
    onChange={(value) => handleInputChange({
      target: { name: 'flRating', value }
    } as React.ChangeEvent<HTMLSelectElement>)}
    options={flRatingOptions}
    placeholder="Select FL Rating"
  />
</div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">SHAME</label>
        <input
          type="number"
          name="embarrassmentLevel"
          value={editedContent.embarrassmentLevel}
          onChange={handleInputChange}
          min="0"
          max="5"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Danger</label>
        <input
          type="number"
          name="riskLevel"
          value={editedContent.riskLevel}
          onChange={handleInputChange}
          min="0"
          max="5"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Effort</label>
        <input
          type="number"
          name="longTermEffects"
          value={editedContent.longTermEffects}
          onChange={handleInputChange}
          min="0"
          max="5"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Equipment Locker</label>
        <input
          type="text"
          name="equipmentLocker"
          value={editedContent.equipmentLocker}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
  <label className="block text-sm font-medium text-gray-700 mb-1">Equipment Needed</label>
  {editedContent.equipmentNeeded.map((item, index) => (
    <div key={index} className="flex items-center gap-2 mb-2">
      <input
        type="text"
        value={item}
        onChange={(e) => handleArrayInputChange(e, index, 'equipmentNeeded')}
        className="flex-grow mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        placeholder={`Equipment ${index + 1}`}
      />
      <button
        type="button"
        onClick={() => {
          const newEquipment = [...editedContent.equipmentNeeded];
          newEquipment.splice(index, 1);
          setEditedContent(prev => ({...prev, equipmentNeeded: newEquipment}));
        }}
        className="p-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
      >
        ✕
      </button>
    </div>
  ))}
  <button
    type="button"
    onClick={() => setEditedContent(prev => ({...prev, equipmentNeeded: [...prev.equipmentNeeded, '']}))}
    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
  >
    Add Equipment
  </button>
</div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Time to Complete</label>
        <input
          type="text"
          name="timeToComplete"
          value={editedContent.timeToComplete}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Cost to Complete</label>
        <input
          type="text"
          name="costToComplete"
          value={editedContent.costToComplete}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        />
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Challenge Instructions</label>
        {editedContent.instructions.map((instruction, index) => (
          <div key={index} className="mt-2 mb-4 p-4 border border-gray-200 rounded-md">
            <textarea
              value={instruction.description}
              onChange={(e) => {
                const newInstructions = [...editedContent.instructions];
                newInstructions[index].description = e.target.value;
                setEditedContent(prev => ({ ...prev, instructions: newInstructions }));
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50 mb-2"
              rows={3}
              placeholder={`Instruction ${index + 1}`}
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  const newInstructions = [...editedContent.instructions];
                  newInstructions[index].image = e.target.files[0];
                  setEditedContent(prev => ({ ...prev, instructions: newInstructions }));
                }
              }}
              className="mt-1 block w-full bg-gray-50 mb-2"
            />
            <button
              type="button"
              onClick={() => {
                const newInstructions = editedContent.instructions.filter((_, i) => i !== index);
                setEditedContent(prev => ({ ...prev, instructions: newInstructions }));
              }}
              className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
            >
              Remove Step
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setEditedContent(prev => ({...prev, instructions: [...prev.instructions, { description: '', image: null }]}))
          }
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
        >
          Add Instruction
        </button>
      </div>
  
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Ways to Spice It Up</label>
        {editedContent.waysToSpiceUp.map((way, index) => (
          <input
            key={index}
            type="text"
            value={way}
            onChange={(e) => handleArrayInputChange(e, index, 'waysToSpiceUp')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50 mb-2"
            placeholder={`Way to Spice It Up ${index + 1}`}
          />
        ))}
        <button
          type="button"
          onClick={() => setEditedContent(prev => ({...prev, waysToSpiceUp: [...prev.waysToSpiceUp, '']}))
          }
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
        >
          Add Way to Spice It Up
        </button>
      </div>

      <div className="mb-4">
  <label className="block text-sm font-medium text-gray-700 mb-1">Social Media Links</label>
  <SocialMediaInput onAdd={handleAddSocialLink} />
  <SocialMediaPreview 
    links={editedContent.socialMediaLinks || []}
    isEditing={true}
    onDelete={handleDeleteSocialLink}
  />
</div>
  
      <div>
  <label className="block text-sm font-medium text-gray-700 mb-1">Tags</label>
  {editedContent.tags.map((tag, index) => (
    <div key={index} className="flex items-center mb-2">
      <input
        type="text"
        value={tag}
        onChange={(e) => handleArrayInputChange(e, index, 'tags')}
        className="flex-grow rounded-l-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-50"
        placeholder={`Tag ${index + 1}`}
      />
      <button
        type="button"
        onClick={() => handleRemoveArrayItem(index, 'tags')}
        className="bg-red-500 text-white px-3 py-2 rounded-r-md hover:bg-red-600 transition duration-200"
      >
        Remove
      </button>
    </div>
  ))}
        <button
          type="button"
          onClick={() => setEditedContent(prev => ({...prev, tags: [...prev.tags, '']}))
          }
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
        >
          Add Tag
        </button>
      </div>
  
      <div className="flex space-x-2">
        <button 
          onClick={handleSave}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
        >
          Save
        </button>
        <button 
          onClick={() => setIsEditing(false)}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200"
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div>
      {isEditing && userRole === 'admin' ? renderEditForm() : renderContent()}
    </div>
  );
};

export default PunishmentDisplay;