import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  FaInstagram, 
  FaTwitter, 
  FaTiktok, 
  FaDiscord as FaDiscordAlt 
} from 'react-icons/fa6';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../services/firebase';

const Footer: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    try {
      await addDoc(collection(db, 'newsletter'), {
        email: email,
        subscribedAt: new Date()
      });
      setMessage('Successfully subscribed!');
      setEmail('');
    } catch (error: any) {
      console.error('Newsletter subscription error:', error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <footer className="bg-[#1A1A1A] text-white py-16 mt-auto">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Left Column */}
          <div className="space-y-6">
            <div className="flex items-start">
              <div>
                <h2 className="font-antonio text-5xl mb-4 font-bold">Join the <br/> conversation</h2>
                <p className="font-antonio font-light text-gray-400 max-w-md">
                  Sign up for the Fantasy Loser newsletter to get weekly challenges, an inside look at punishments, and more as we continue to develop.
                </p>
              </div>
              <img 
                src={require('../assets/FL_Logo_FL_Icon_Primary_2.png')}
                alt="FL Logo"
                className="w-20 h-20 mt-[12%] -ml-[30%]" 
              />
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-4 max-w-md">
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Subscribe to our newsletter"
                  className="w-full bg-transparent border-b border-white/20 py-2 pr-12 
                           placeholder-gray-500 focus:outline-none focus:border-[#18f7b1]
                           transition-colors font-antonio font-light"
                  required
                />
                <button 
                  type="submit" 
                  className="absolute right-0 top-1/2 -translate-y-1/2"
                  disabled={isLoading}
                >
                  →
                </button>
              </div>
              {message && (
                <p className={`text-sm ${
                  message.startsWith('Error') ? 'text-red-500' : 'text-[#18f7b1]'
                }`}>
                  {message}
                </p>
              )}
            </form>
          </div>

          {/* Right Column */}
          <div className="flex flex-col lg:items-end lg:justify-between">
            <div className="space-y-8">
              <nav className="flex flex-col items-center space-y-8">
                <div className="flex flex-col lg:flex-row items-center lg:items-end lg:space-x-12 space-y-6 lg:space-y-0">
                  <Link to="/punishments" className="font-antonio text-4xl hover:text-[#18f7b1] transition-colors">
                    Punishments
                  </Link>
                  <Link to="/challenges" className="font-antonio text-4xl hover:text-[#18f7b1] transition-colors">
                    Challenges
                  </Link>
                  <Link to="/login" className="font-antonio text-4xl hover:text-[#18f7b1] transition-colors">
                    Login
                  </Link>
                </div>
                <br />
                <br />
                <br />
                <div className="flex flex-col lg:flex-row items-center lg:items-end lg:space-x-12 space-y-6 lg:space-y-0">
                  <Link to="/privacy-policy" className="font-antonio text-4xl hover:text-[#18f7b1] transition-colors">
                    Privacy Policy
                  </Link>
                  <Link to="/terms-of-service" className="font-antonio text-4xl hover:text-[#18f7b1] transition-colors">
                    Terms of Service
                  </Link>
                </div>
              </nav>

              <div className="flex space-x-6 justify-center lg:justify-end">
                <a href="https://www.instagram.com/fantasyloserhq/" target="_blank" rel="noopener noreferrer" className="hover:text-[#18f7b1] transition-colors">
                  <FaInstagram size={24} />
                </a>
                <a href="https://x.com/fantasyloserhq" target="_blank" rel="noopener noreferrer" className="hover:text-[#18f7b1] transition-colors">
                  <FaTwitter size={24} />
                </a>
                <a href="https://www.tiktok.com/@fantasyloserhq" target="_blank" rel="noopener noreferrer" className="hover:text-[#18f7b1] transition-colors">
                  <FaTiktok size={24} />
                </a>
                <a href="https://discord.gg/H6TADUTph2" target="_blank" rel="noopener noreferrer" className="hover:text-[#18f7b1] transition-colors">
                  <FaDiscordAlt size={24} />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-12 text-center">
          <p className="font-antonio text-2xl hover:text-[#18f7b1] transition-colors">
            © {new Date().getFullYear()} Fantasy Loser Interactive, Inc. All rights reserved.
             <Link to="/privacy-policy" className="font-antonio text-2xl hover:text-[#18f7b1] transition-colors">
                      Privacy Policy
                  </Link>.
                  <Link to="/terms-of-service" className="font-antonio text-2xl hover:text-[#18f7b1] transition-colors">
                      Terms of Service
                  </Link>.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
