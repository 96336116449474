import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import AdminPage from './pages/AdminPage';
import PrivateRoute from './components/PrivateRoute';
import CommissionerDashboard from './pages/CommissionerDashboard';
import { ThemeProvider } from './contexts/ThemeContext';
import ContentPage from './pages/ContentPage';
import LeaguePage from './pages/LeagueLocker';
import PasswordResetPage from './pages/PasswordReset';
import ExplorePage from './pages/Explore';
import Hotjar from '@hotjar/browser';
import { YahooAuthProvider } from './contexts/YahooAuthContext';
import YahooCallback from './components/YahooCallback';
import TeamSelection from './components/leaguePages/TeamSelection';
import WeeklyChallengesPage from './pages/WeeklyChallengesPage';
import ChallengeDetailPage from './pages/ChallengeDetailPage'; 
import { ModalProvider } from './contexts/ModalContext';
import ScrollToTop from './components/ui/ScrollToTop';
import { Helmet } from 'react-helmet';
import { FirebaseAnalyticsProvider } from './services/AnalyticsProvider';
import MarkdownProvider from './contexts/MarkdownContext';
import WheelOfShamePage from './pages/WheelOfShamePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const siteId = 5151409;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const App: React.FC = () => {
  return (
    <main className='min-w-screen overflow-x-hidden'>
    <Router>
      
         

            <Helmet>
        <title>Fantasy Loser</title>
        <meta name="description" content="The premier site for fantasy sports and fantasy punishments" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <YahooAuthProvider>
        <AuthProvider>
        
          <ThemeProvider>  
            <MarkdownProvider>
            <ScrollToTop />
            <ModalProvider>
            <FirebaseAnalyticsProvider>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route 
                  path="/admin" 
                  element={
                    <PrivateRoute 
                      component={AdminPage} 
                      allowedRoles={['admin']} 
                    />
                  } 
                />
                <Route 
                  path="/dashboard" 
                  element={
                    <PrivateRoute 
                      component={CommissionerDashboard} 
                      allowedRoles={['admin', 'commissioner']} 
                    />
                  } 
                />
                <Route 
                  path="/sign-up" 
                  element={
                    <PrivateRoute 
                      component={SignupPage} 
                      allowedRoles={['admin']} 
                    />
                  } 
                />
                <Route 
                  path="/punishments/:slug" 
                  element={<ContentPage />} 
                />
                <Route 
                  path="/punishments" 
                  element={<ExplorePage />} 
                />
                   <Route 
                  path="/challenges" 
                  element={<WeeklyChallengesPage />} 
                />
                <Route 
                  path="/challenges/:id" 
                  element={<ChallengeDetailPage />} 
                />
                <Route path="/league/:id/*" element={<LeaguePage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route path="/reset-password" element={<PasswordResetPage />} />
                <Route path="/yahoo-callback" element={<YahooCallback />} />
                <Route path="/league/:id/join" element={<TeamSelection />} />
                <Route path="/wheelofshame" element={<WheelOfShamePage />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
              </Routes>
              </FirebaseAnalyticsProvider>
              
              </ModalProvider>  
              </MarkdownProvider> 
          </ThemeProvider>
          
        </AuthProvider>
      </YahooAuthProvider>
    
      
      
    </Router>
    </main>
  );
};

export default App;