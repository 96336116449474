import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { getContentById, getUserRole, voteContent, getContentVotes, getRandomContent, getContentBySlug } from '../services/firebase';
import { ContentItem } from '../types';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import PunishmentDisplay from '../components/PunishmentDisplay';
import ContentCarousel from '../components/ContentCarousel';
import Navbar from '../components/Navbar';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';
import MetaHead from '../components/ui/MetaHead';

const ContentPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [content, setContent] = useState<ContentItem | null>(null);
  const [carouselItems, setCarouselItems] = useState<ContentItem[]>([]);
  const { theme } = useTheme();
  const { user } = useAuth();
  const [userRole, setUserRole] = useState<string | null>(null);
  const [upvotes, setUpvotes] = useState(0);
  const [downvotes, setDownvotes] = useState(0);
  const [userVote, setUserVote] = useState<'up' | 'down' | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      if (!slug) return;

      try {
        let fetchedContent: ContentItem | null = await getContentBySlug(slug);
        
        if (!fetchedContent) {
          fetchedContent = await getContentById(slug);
          
          if (fetchedContent?.slug) {
            navigate(`/punishments/${fetchedContent.slug}`, { replace: true });
            return;
          }
        }

        if (fetchedContent) {
          setContent(fetchedContent);
        } else {
          console.error('Content not found');
          navigate('/punishments');
        }
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    const fetchUserRole = async () => {
      if (user) {
        try {
          const role = await getUserRole(user.uid);
          setUserRole(role);
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      } else {
        setUserRole(null);
      }
    };

    const fetchCarouselItems = async () => {
      try {
        const items = await getRandomContent(6);
        setCarouselItems(items);
      } catch (error) {
        console.error('Error fetching carousel items:', error);
      }
    };

    fetchContent();
    fetchUserRole();
    fetchCarouselItems();
  }, [slug, user, navigate]);

  useEffect(() => {
    const fetchVotes = async () => {
      if (slug) {
        try {
          const votes = await getContentVotes(slug);
          setUpvotes(votes.upvotes);
          setDownvotes(votes.downvotes);
          if (user) {
            setUserVote(votes.userVotes[user.uid] || null);
          } else {
            setUserVote(null);
          }
        } catch (error) {
          console.error('Error fetching votes:', error);
        }
      }
      setIsLoading(false);
    };

    fetchVotes();
  }, [slug, user]);

  const handleVote = async (voteType: 'up' | 'down') => {
    if (!content || !user || (userRole !== 'commissioner' && userRole !== 'admin')) {
      return;
    }

    try {
      const newVoteType = userVote === voteType ? null : voteType;
      const result = await voteContent(content.id, user.uid, newVoteType);
      setUpvotes(result.upvotes);
      setDownvotes(result.downvotes);
      setUserVote(result.userVote);
    } catch (error) {
      console.error('Error voting:', error);
    }
  };

  const handleCarouselItemClick = (item: ContentItem) => {
    if (item.slug) {
      navigate(`/punishments/${item.slug}`);
    } else {
      navigate(`/punishments/${item.id}`);
    }
  };


  if (!content) {
    return <div>Content not found</div>;
  }

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {content && (
   <MetaHead
   title={`${content.title} - Fantasy Football Punishment`}
   description={`${content.subtitle || content.imageDescription || `Check out this ${content.title} fantasy football punishment`}. Find the perfect last place punishment for your fantasy league losers and make losing memorable.`}
   image={typeof content.image === 'string' ? content.image : undefined}
   url={`https://fantasyloser.com/punishments/${content.slug || content.id}`}
   type="article"
   keywords={[
     'fantasy football punishment',
     'fantasy football loser',
     content.title.toLowerCase(),
     ...content.tags,
     'last place punishment',
     'fantasy league punishment',
     'loser consequence',
     'fantasy football forfeit',
     'league punishment idea'
   ]}
 />
)}
      <Navbar />
      <main className="flex-grow pt-[50px]">
        <div className="w-full">
          <PunishmentDisplay
            content={content}
            userRole={userRole}
            onVote={handleVote}
            upvotes={upvotes}    
            downvotes={downvotes}
            userVote={userVote}
          />
        </div>

    
      </main>
      <Footer />
    </div>
  );
};

export default ContentPage;