import React, { useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addContent, storage } from '../services/firebase';
import { NewContentItem, ContentItem } from '../types';
import { useNavigate } from 'react-router-dom';
import { generateSlug, getContentPath } from './utils/generateSlug';
import { MarkdownField } from './ui/MarkDown';

const AdminForm: React.FC = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [contentType, setContentType] = useState<'punishment' | 'challenge' | 'draft'>('punishment');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [imageDescription, setImageDescription] = useState('');
  const [flRating, setFlRating] = useState('');
  const [embarrassmentLevel, setEmbarrassmentLevel] = useState('');
  const [riskLevel, setRiskLevel] = useState('');
  const [longTermEffects, setLongTermEffects] = useState('');
  const [equipmentLocker, setEquipmentLocker] = useState('');
  const [equipmentNeeded, setEquipmentNeeded] = useState<string[]>(['']);
  const [timeToComplete, setTimeToComplete] = useState('');
  const [costToComplete, setCostToComplete] = useState('');
  const [waysToSpiceUp, setWaysToSpiceUp] = useState<string[]>(['']);
  const [instructions, setInstructions] = useState<{ description: string; image?: File | string | null }[]>([{ description: '' }]);
  const [tags, setTags] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [cardImage, setCardImage] = useState<File | null>(null);
const [imageCredit, setImageCredit] = useState('');
const [mobileHeaderImage, setMobileHeaderImage] = useState<File | null>(null);

const uploadImage = async (file: File | null, path: string): Promise<string | null> => {
  if (!file) return null;
  
  try {
    const storageRef = ref(storage, `${path}/${file.name}-${Date.now()}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

  const handleEquipmentChange = (index: number, value: string) => {
    const updatedEquipment = [...equipmentNeeded];
    updatedEquipment[index] = value;
    setEquipmentNeeded(updatedEquipment);
  };

  const handleWaysToSpiceUpChange = (index: number, value: string) => {
    const updatedWays = [...waysToSpiceUp];
    updatedWays[index] = value;
    setWaysToSpiceUp(updatedWays);
  };

  const handleInstructionChange = (index: number, value: string) => {
    const updatedInstructions = [...instructions];
    updatedInstructions[index].description = value;
    setInstructions(updatedInstructions);
  };
  
  const handleInstructionImageChange = async (index: number, file: File | null) => {
    if (file) {
      const storageRef = ref(storage, `instructions/${file.name}-${Date.now()}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
  
      const updatedInstructions = [...instructions];
      updatedInstructions[index].image = downloadURL;
      setInstructions(updatedInstructions);
    } else {
      const updatedInstructions = [...instructions];
      updatedInstructions[index].image = undefined;
      setInstructions(updatedInstructions);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    const mainImageUrl = await uploadImage(image, 'main-images');
    const cardImageUrl = await uploadImage(cardImage, 'card-images');
    const mobileHeaderImageUrl = await uploadImage(mobileHeaderImage, 'mobile-headers');
    
    try {
      const slug = generateSlug(title);
      const newContentItem: Omit<ContentItem, 'id'> = {
        type: contentType,
        title: title || '',
        slug,
        subtitle: subtitle || '',
        image: mainImageUrl || '',
        imageDescription: imageDescription || '',
        flRating: flRating || '',
        embarrassmentLevel: embarrassmentLevel || '',
        riskLevel: riskLevel || '',
        longTermEffects: longTermEffects || '',
        equipmentLocker: equipmentLocker || '',
        equipmentNeeded: equipmentNeeded.filter(item => item.trim() !== ''),
        timeToComplete: timeToComplete || '',
        costToComplete: costToComplete || '',
        waysToSpiceUp: waysToSpiceUp.filter(item => item.trim() !== ''),
        instructions: instructions.map(instruction => ({
          description: instruction.description || '',
          image: instruction.image || ''
        })),
        tags: tags || [],
        createdAt: new Date(),
        cardImage: cardImageUrl || '',
        imageCredit: imageCredit || 'Fantasy Loser',
        mobileHeaderImage: mobileHeaderImageUrl || '',
      };
  
      const newContentId = await addContent(newContentItem);
    alert('Content added successfully!');
    
    navigate(getContentPath(newContentId, slug));
  
      // Reset form
      setContentType('punishment');
      setTitle('');
      setSubtitle('');
      setImage(null);
      setImageDescription('');
      setFlRating('');
      setEmbarrassmentLevel('');
      setRiskLevel('');
      setLongTermEffects('');
      setEquipmentLocker('');
      setEquipmentNeeded(['']);
      setTimeToComplete('');
      setCostToComplete('');
      setWaysToSpiceUp(['']);
      setInstructions([{ description: '' }]);
      setTags([]);
    } catch (error) {
      if (error instanceof Error) {
        setError(`Error adding content: ${error.message}`);
      } else {
        setError('An unknown error occurred while adding content');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className={`${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} shadow-md rounded px-8 pt-6 pb-8 mb-4`}>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="contentType">
          Content Type
        </label>
        <select
          id="contentType"
          value={contentType}
          onChange={(e) => setContentType(e.target.value as 'punishment' | 'challenge' | 'draft')}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
        >
          <option value="punishment">Punishment</option>
          <option value="challenge">Challenge</option>
          <option value="draft">Draft Content</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="title">
          Title
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="subtitle">
          Subtitle
        </label>
        <MarkdownField
          value={subtitle}
          onChange={(value) => setSubtitle(value || '')}
          placeholder="Enter subtitle with optional formatting"
          minHeight={100}
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="image">
          Image
        </label>
        <input
          type="file"
          id="image"
          onChange={handleImageChange}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
        />
      </div>

     
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="image">
          Main Image
        </label>
        <input
          type="file"
          id="image"
          onChange={handleImageChange}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
        />
      </div>

      <div className="mb-4">
  <label className="block text-sm font-bold mb-2" htmlFor="mobileHeaderImage">
    Mobile Header Image (Optional)
  </label>
  <input
    type="file"
    id="mobileHeaderImage"
    onChange={(e) => {
      if (e.target.files && e.target.files[0]) {
        setMobileHeaderImage(e.target.files[0]);
      }
    }}
    className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
  />
  <p className="text-sm text-gray-500 mt-1">This image will be used as the header on mobile devices. If not provided, the main image will be used.</p>
</div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="cardImage">
          Card Image (Optional - for explore page)
        </label>
        <input
          type="file"
          id="cardImage"
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              setCardImage(e.target.files[0]);
            }
          }}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
        />
        <p className="text-sm text-gray-500 mt-1">If not provided, the main image will be used for cards</p>
      </div>

<div className="mb-4">
  <label className="block text-sm font-bold mb-2" htmlFor="imageCredit">
    Image Credit (Optional)
  </label>
  <input
    type="text"
    id="imageCredit"
    value={imageCredit}
    onChange={(e) => setImageCredit(e.target.value)}
    placeholder="e.g., Photo by John Doe"
    className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
  />
</div>

      <div className="mb-4">
        <label className="block mb-2">Image Description</label>
        <MarkdownField
          value={imageDescription}
          onChange={(value) => setImageDescription(value || '')}
          placeholder="Describe the image with optional formatting"
          minHeight={150}
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="flRating">
          FL Rating
        </label>
        <select
          id="flRating"
          value={flRating}
          onChange={(e) => setFlRating(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
        >
          <option value="">Select FL Rating</option>
          <option value="Pee Wee Level 1 | Basic ">Pee Wee <br/>Level 1 | Basic</option>
          <option value="Two Hand Touch Level 2 | Challenging">Two Hand Touch <br/>Level 2 | Challenging</option>
          <option value="Full Contact Level 3 | Serious">Full Contact <br/>Level 3 | Serious</option>
          <option value="All Pro Elite Level 4 | Ruthless">All Pro Elite <br/>Level 4 | Ruthless</option>
          <option value="No Mercy Level 5 | Insane">No Mercy <br/> Level 5 | Insane</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="embarrassmentLevel">
          Shame
        </label>
        <select
          id="embarrassmentLevel"
          value={embarrassmentLevel}
          onChange={(e) => setEmbarrassmentLevel(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
        >
          <option value="">Select level</option>
          {[1, 2, 3, 4, 5].map((num) => (
            <option key={num} value={num.toString()}>{num}</option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="riskLevel">
          Danger
        </label>
        <select
          id="riskLevel"
          value={riskLevel}
          onChange={(e) => setRiskLevel(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
        >
          <option value="">Select level</option>
          {[1, 2, 3, 4, 5].map((num) => (
            <option key={num} value={num.toString()}>{num}</option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="longTermEffects">
          Effort
        </label>
        <select
          id="longTermEffects"
          value={longTermEffects}
          onChange={(e) => setLongTermEffects(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
        >
          <option value="">Select level</option>
          {[1, 2, 3, 4, 5].map((num) => (
            <option key={num} value={num.toString()}>{num}</option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block mb-2">Equipment Locker</label>
        <MarkdownField
          value={equipmentLocker}
          onChange={(value) => setEquipmentLocker(value || '')}
          placeholder="Enter equipment locker details with optional formatting"
          minHeight={150}
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Equipment Needed</label>
        {equipmentNeeded.map((item, index) => (
          <div key={index} className="flex mb-2">
            <input
              type="text"
              value={item}
              onChange={(e) => handleEquipmentChange(index, e.target.value)}
              className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
            />
            {index === equipmentNeeded.length - 1 && (
              <button
                type="button"
                onClick={() => setEquipmentNeeded([...equipmentNeeded, ''])}
                className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                +
              </button>
            )}
          </div>
        ))}
      </div>

      <div className="mb-4">
        <label className="block mb-2">Time to Complete</label>
        <input
          type="text"
          value={timeToComplete}
          onChange={(e) => setTimeToComplete(e.target.value)}
          className={`w-full p-2 rounded ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200'}`}
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2">Cost to Complete</label>
        <input
          type="text"
          value={costToComplete}
          onChange={(e) => setCostToComplete(e.target.value)}
          className={`w-full p-2 rounded ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200'}`}
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2">Ways to Spice It Up</label>
        {waysToSpiceUp.map((way, index) => (
          <div key={index} className="mb-4">
            <MarkdownField
              value={way}
              onChange={(value) => handleWaysToSpiceUpChange(index, value || '')}
              placeholder="Enter way to spice it up with optional formatting"
              minHeight={100}
            />
          </div>
        ))}
        <button
          type="button"
          onClick={() => setWaysToSpiceUp([...waysToSpiceUp, ''])}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Way to Spice It Up
        </button>
      </div>

      <div className="mb-4">
        <label className="block mb-2">Challenge Instructions</label>
        {instructions.map((instruction, index) => (
          <div key={index} className="mb-4">
            <MarkdownField
              value={instruction.description}
              onChange={(value) => handleInstructionChange(index, value || '')}
              placeholder="Instruction description with optional formatting"
              minHeight={150}
            />
            <input
              type="file"
              onChange={(e) => handleInstructionImageChange(index, e.target.files ? e.target.files[0] : null)}
              className={`w-full p-2 rounded ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200'}`}
            />
          </div>
        ))}
        <button
          type="button"
          onClick={() => setInstructions([...instructions, { description: '', image: null }])}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Instruction
        </button>
      </div>

      <div className="flex items-center justify-between">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Submit
        </button>
      </div>

      {error && (
        <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
    </form>
  );
};

export default AdminForm;